import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import ContentDiv from "../components/contentDiv"
import FirstSection from "../components/firstSection"
import Layout from "../components/layout"
import PageImage from "../components/pageImage"
import PageSection from "../components/pageSection"
import PartnerBox from "../components/partnerBox"
import getDummyText from "../dummyText"
import Map from "../components/map"
import HeaderDiv from "../components/headerDiv"
import Text, { TextType } from "../components/text"
import { phone, laptop, tablet } from "../util/mediaQueries"
import { Media } from "gatsby-plugin-fresnel"
import SEO from "../components/seo"

const PartnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media ${laptop} {
    flex-direction: row;
  }
  justify-content: space-between;
  align-items: center;
`

const MapDiv = styled.div`
  border-radius: 10px;
  @media ${tablet} {
    margin: 0;
    border-radius: 10px;
  }
  width: 100%;
  height: 500px;
  overflow: hidden;
  box-shadow: ${props => props.theme.shadow};
  &:hover {
    box-shadow: ${props => props.theme.shadowHovered};
  }
  transition: box-shadow 0.2s;
`

const GreyHeader = styled.div`
  background-color: ${props => props.theme.second};
  text-align: center;
  width: 100%;
  padding: 20px;
`

const StyledMedia = styled(Media)`
  min-width: 100%;
`

const UeberUns = () => (
  <Layout>
    <SEO lang="de" title="Über uns" />
    <PageImage>
      <Media greaterThanOrEqual="md">
        <StaticImage
          src="../images/Titelbild_Ueber_Uns_PC.jpg"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Image"
          objectFit="contain"
          layout="fullWidth"
        />
      </Media>
      <Media at="sm">
        <StaticImage
          src="../images/Titelbild_Ueber_Uns_Smartp.jpg"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Image"
          objectFit="contain"
          layout="fullWidth"
        />
      </Media>
    </PageImage>
    <FirstSection headerText="Die Kanzlei">
      Unter professioneller Steuerberatung verstehen wir mehr als nur ein
      einmaliges Beratungsgespräch. Wir stellen Ihre individuellen Bedürfnisse
      in den Mittelpunkt, nehmen uns Zeit für Ihre Anliegen und agieren als Ihr
      beständiger Gesprächspartner. Gemeinsam analysieren wir Ihre
      Fragestellungen und planen die bestmöglichen Lösungen. Für uns ist es
      selbstverständlich, dass wir Sie bei der Umsetzung der erforderlichen
      Maßnahmen umfassend unterstützen und Ihnen auch bei der Gestaltung Ihrer
      Unternehmenszukunft als Gesprächspartner zur Verfügung stehen.
    </FirstSection>
    <GreyHeader>
      <Text type={TextType.Header}>Ihre Ansprechpartner</Text>
    </GreyHeader>
    <PageSection>
      <ContentDiv>
        <PartnerDiv>
          <PartnerBox
            image={1}
            name="Michael Dietrich"
            description="Steuerberater & Partner"
            email="m.dietrich@dietrich-suess.de"
            phonePre="89"
            phoneSuf="82085970"
          />
          <PartnerBox
            image={2}
            name="Alexander Süß"
            description="Steuerberater & Partner"
            email="a.suess@dietrich-suess.de"
            phonePre="89"
            phoneSuf="82085970"
          />
          <PartnerBox
            image={3}
            name="Jochen Jaschke"
            description="Steuerberater & Partner"
            email="j.jaschke@dietrich-suess.de"
            phonePre="89"
            phoneSuf="82085970"
          />
        </PartnerDiv>
      </ContentDiv>
    </PageSection>

    <StyledMedia greaterThanOrEqual="md">
      <GreyHeader>
        <Text type={TextType.Header}>Anfahrt</Text>
      </GreyHeader>
      <PageSection>
        <ContentDiv id="anfahrt">
          <MapDiv>
            <Map height={500} />
          </MapDiv>
        </ContentDiv>
      </PageSection>
    </StyledMedia>
  </Layout>
)

export default UeberUns
