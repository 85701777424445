import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { FiMail, FiPhone } from "react-icons/fi"
import styled from "styled-components"
import { laptop, tablet } from "../util/mediaQueries"
import Mail from "./mail"
import PhoneNumber from "./PhoneNumber"
import Text, { TextType } from "./text"

interface Props {
    image: number
    name: string
    description: string
    email: string
    phonePre: string
    phoneSuf: string
}
const RootDiv = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    box-shadow: ${props => props.theme.shadow};
    &:hover {
        box-shadow: ${props => props.theme.shadowHovered};
    }
    transition: box-shadow 0.2s;
    border-radius: 10px;
    margin-bottom: 40px;
    width: 100%;
    @media ${tablet} {
      width: 500px;
    }
    @media ${laptop} {
       width: 30%;
       margin-bottom: 0;
    }
    text-align: center;
    background: white;
`

const ImgDiv = styled.div`
    overflow: hidden;
`

const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    margin-top: 10px;
    border-bottom: 2px solid ${props => props.theme.main};
    margin-bottom: 5px;
`

const InfoDiv = styled.div`
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    margin-top: 10px;
    margin-bottom: 10px;
`
const PartnerDesc = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`

const ButtonDiv = styled.div`
    height: 30px;
    width: 30px;
    margin-right: 10px;
`
const ImageDietrich = () => (
    <StaticImage
        src="../images/Ueber_Uns_Michi.jpg"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Image"
        layout="fullWidth"
    />
)

const ImageSuess = () => (
    <StaticImage
        src="../images/Ueber_Uns_Alex.jpg"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Image"
        layout="fullWidth"
    />
)

const ImageJaschke = () => (
    <StaticImage
        src="../images/Ueber_uns_Jochen.jpg"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Image"
        layout="fullWidth"
    />
)

const BodyDiv = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
`

const PartnerName = styled.span`
    font-size: ${props => props.theme.subHeaderSize};
    color: ${props => props.theme.main};
`

const PartnerBox = (props: Props) => (
    <RootDiv>
        <ImgDiv>
            {props.image === 1 ? (<ImageDietrich />) : props.image === 2 ? (<ImageSuess />) : (<ImageJaschke />)}
        </ImgDiv>
        <BodyDiv>
            <TextDiv>
                <PartnerName>{props.name}</PartnerName>
                <PartnerDesc>
                    <Text type={TextType.Body} >{props.description}</Text>
                </PartnerDesc>
            </TextDiv>
            <InfoDiv>
                <ButtonDiv>
                    <FiMail size="30px" color="#4d6888" />
                </ButtonDiv>
                <Mail mail={props.email} />
            </InfoDiv>
            <InfoDiv>
                <ButtonDiv>
                    <FiPhone size="30px" color="#4d6888" />
                </ButtonDiv>
                <PhoneNumber numPre={props.phonePre} numSuf={props.phoneSuf} />
            </InfoDiv>
        </BodyDiv>
    </RootDiv>
)

export default PartnerBox
